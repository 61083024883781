import React from 'react';
import { motion } from 'framer-motion';
import './ContactPage.css';

const ContactPage = () => {
  return (
    <motion.div
      className="contact-page"
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="contact-container">
        <h1>Contact Us</h1>
        <div className="contact-card">
          <h2>Music For Us</h2>
          <p>
            <strong>Email:</strong>{' '}
            <a href="mailto:contact@music-for-us.com">
              contact@music-for-us.com
            </a>
          </p>
          <p>
            <strong>Business Number:</strong>{' '}
            <a href="tel:2137595094">213-759-5094</a>
          </p>
          <p>
            <strong>Business Address:</strong> 8605 Santa Monica Blvd #930549,
            West Hollywood, CA 90069
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactPage;
