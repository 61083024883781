import React from 'react';
import { motion } from 'framer-motion';
import './DonatePage.css';

const PAYPAL_DONATION_URL = "https://www.paypal.me/ZacharyCash780";

const DonatePage = () => {
  return (
    <motion.div 
      className="donate-page container-card"
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h1>Gift</h1>
      <p>
        Your generous gifts help us continue our mission of spreading music and inspiration. 
        To send us a gift, you can send money via PayPal to <strong>dhaliaband@gmail.com</strong>.
        Enter "Gift to Music For Us" in the "What's this for?" field if you're sending from the link below.
      </p>
      <a href={PAYPAL_DONATION_URL} target="_blank" rel="noopener noreferrer">
        <button className="donate-button">Send Gift Now</button>
      </a>
    </motion.div>
  );
};

export default DonatePage;
