import React from 'react';
import { motion } from 'framer-motion';
import './LandingPage.css';

const testimonialVideos = [
  {
    src: require('../assets/testimonial1.mov'),
    poster: require('../assets/testimonial1.jpg'),
    label: 'Testimonial: Kellin Quinn (Sleeping With Sirens)'
  },
  {
    src: require('../assets/testimonial2.mov'),
    poster: require('../assets/testimonial2.jpg'),
    label: 'Testimonial: Julian Comeau (Loveless)'
  },
  {
    src: require('../assets/testimonial3.mov'),
    poster: require('../assets/testimonial3.jpg'),
    label: 'Testimonial: Ladybeard'
  },
  {
    src: require('../assets/testimonial4.mov'),
    poster: require('../assets/testimonial4.jpg'),
    label: 'Testimonial: Alex Kendrick (FOH - Atilla)'
  },
  {
    src: require('../assets/testimonial5.mov'),
    poster: require('../assets/testimonial5.jpg'),
    label: 'Testimonial: Chris Blanchi (CB Entertainment)'
  },
  {
    src: require('../assets/testimonial6.mov'),
    poster: require('../assets/testimonial6.jpg'),
    label: 'Testimonial: Mark Barela (At The Skylines)'
  },
  {
    src: require('../assets/testimonial7.mov'),
    poster: require('../assets/testimonial7.jpg'),
    label: 'Testimonial: Zack Meegan (Ghostkiller Entertainment)'
  }
];

const LandingPage = () => {
  return (
    <motion.div  
      className="landing-page"
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }}  
      transition={{ duration: 1 }}
    >
      <h1>Welcome to Music For Us</h1>
      
      {/* Landing Page Landscape Video */}
      <div className="landingpage-video-wrapper landscape">
        <video 
          src={require('../assets/landingpagevideo.mov')} 
          poster={require('../assets/landingpagevideo.jpg')} 
          controls 
        />
      </div>

      <div className="video-grid">
        {testimonialVideos.map((video, index) => (
          <motion.div key={index} className="testimonial-container">
            <div className="testimonial-label">
              <h3>{video.label}</h3>
            </div>
            <motion.div 
              className="video-wrapper portrait"
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <video src={video.src} poster={video.poster} controls />
            </motion.div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default LandingPage;
