import React from 'react';
import './AboutPage.css';
import zakk1 from '../assets/zakk1.jpeg';
import zakk2 from '../assets/zakk2.jpeg';

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>About Us</h1>
      <img src={zakk2} alt="Zakk Cash with a guitar" className="about-image" />
      <p>
        Music For Us LLC was founded with a simple yet powerful mission: to share the transformative power of music with people from all walks of life, with a special focus on the Down syndrome community.
      </p>
      <p>
        Created by chart-topping singer/songwriter Zakk Cash, Music For Us is more than just a company—it's a movement driven by love, inclusivity, and the belief that music has the ability to uplift, inspire, and connect us all. Through performances, events, and creative initiatives, we strive to make music accessible to those who need it most, fostering joy and self-expression in every note.
      </p>
      <p>
        At Music For Us, we believe that music isn’t just something we listen to—it’s something we share. And together, we make it for us all.
      </p>
      <h2>Zakk Cash - Founder and Instructor</h2>
      <p>
        Zakk Cash is a chart-topping singer, songwriter, and musician whose passion for music runs deep. Born and raised in Georgia, Cash picked up the guitar at just seven years old, surrounded by a family where music was more than just a pastime—it was a way of life. Their grandmother played piano, their mother and grandfather sang, and their biggest inspiration came from their uncle Bud, who had Down syndrome and was not only their best friend but also their greatest influence. Bud’s love for Bon Jovi and singing sparked something in Cash, igniting a lifelong journey in music.
      </p>
      <p>
        Over the years, Cash built a career that saw their songs climb major global charts, but in 2023, life took an unexpected turn when they were diagnosed with MS. Faced with the need to shift their career path, they found clarity in what had always mattered most—sharing music with the people they love. With the encouragement of family and friends, Cash founded Music For Us LLC, a program dedicated to bringing the joy of music to all, with a special focus on the Down syndrome community.
      </p>
      <p>
        Through this work, Cash continues to honor Bud’s memory and legacy, believing wholeheartedly that this is what he would have wanted. Their mission is simple: to use music as a bridge for love, connection, and inclusion—because music isn’t just something we hear, it’s something we share.
      </p>
      <div className="about-images">
        <img src={zakk1} alt="Zakk Cash performing" className="about-image" />
      </div>
    </div>
  );
};

export default AboutPage;
